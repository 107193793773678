<template>
    <div>
      <h1 class="display-3 d-flex align-items-center font-weight-light overflow-hidden position-absolute text-white" style="height: 66px; margin: 0 0 0 30px; top: 0; z-index: 1">
        <h6 class="h2 text-white d-inline-block mb-0"><i class="ni ni-badge"></i> Pendaftaran Pengolahan Kembali Air Limbah</h6>
      </h1>
  
      <base-header class="pb-6">
     
      </base-header>
  
      
  
      <b-container fluid class="mt--6">
        <b-row>
          <b-col xl="12">
            <b-card>
              <template v-slot:header>
                <b-row>
                  <b-col xl="12" md="12">
                      <h5>Cari Berdasarkan</h5>
                      <div class="d-flex justify-content-start rounded p-3" style="background-color: #eaeaea;">

                          <!-- status pendaftaran -->
                          <div style="display: flex;flex-direction: column;width: 180px; margin-right: 10px;">
                            <label><small>Status Pendaftaran</small></label>
                            <el-select class="select-primary pagination-select" v-model="filterStatusPendaftaran"  placeholder="Status Pendaftaran">
                            <el-option
                                class="select-primary"
                                v-for="item in selectOptionStatus"
                                :key="item.val"
                                :label="item.name"
                                :value="item.val"
                            >
                            </el-option>
                            </el-select>
                          </div>

                          <!-- Tanggal -->
                          <div style="display: flex;flex-direction: column;width: 180px;margin-right: 10px;">
                            <label><small>Tanggal</small></label>
                            <base-input name="Tanggal">
                              <flat-picker
                                placeholder="Filter Tanggal"
                                :config="provideSelect.flatPickerConfig"
                                class="form-control datepicker"
                                v-model="userInput.tanggal"
                                style="background:white;"
                              />
                            </base-input>
                          </div>

                          <div class="col-2">
                            <base-button size="md" type="primary" style="margin-top: 33px;" @click="getBaseOnStatus"> Tampilkan </base-button>
                          </div>
                          

                          
                            
                      </div>
                      <div class="d-flex justify-content-start">

                      </div>
                  </b-col>
                </b-row>
              </template>

                <b-row  class="p-3 rounded">
                  <b-col lg="3" cols="12">
                    <h3 class="text-primary mt-2">Pemanfaatan Kembali Air Limbah</h3>
                  </b-col>
                  <b-col lg="9" cols="12" style="display: flex; justify-content: flex-end">
                    <!-- <button
                    class="btn text-white"
                    style="background-color: #b51308;"
                    @click="downloadPDFNow"
                  >
                    <i class="fa fa-solid fa-file-pdf mr-2" style="font-size: 20px;"></i>
                      Unduh PDF
                  </button>
                  <button
                    class="btn text-white"
                    style="background-color: #0a7641;"
                    @click="downloadExcelNow"
                  >
                    <i class="fa fa-solid fa-file-excel mr-2" style="font-size: 20px;"></i>
                      Unduh Excel
                  </button> -->
                    <base-button
                      size="md"
                      type="primary"
                      @click="goToRegisSparingWasteWater"
                    >
                      + Buat Pendaftaran baru
                    </base-button>
                  </b-col>
                </b-row>
  
       
                <section id="mytable">
                  <el-table
                    :data="listData"
                    row-key="id"
                    header-row-class-name="thead-light"
                  >

                    <el-table-column label="No" type="index" width="100" />

                    <el-table-column max-width="120px"  label="STATUS">
                      <div slot-scope="action">
                        <div :style="getStyleObject(action.row.status)" class="text-white p-1 text-center rounded">
                          {{ action.row.status }}
                        </div>
                      </div>
                    </el-table-column>

                    <el-table-column min-width="220px"  label="dibuat">
                      <div slot-scope="action">
                        {{ formatDate(action.row.created_at)  }}
                      </div>
                    </el-table-column>


                    <el-table-column min-width="250px"  label="AKSI">
                      <div slot-scope="action">
                          <base-button
                          @click="view(action.row.id)"
                          type="secondary"
                          size="sm"
                          icon
                          >
                          RINCIAN
                          </base-button>
                          <!-- edit -->
                          <base-button
                          @click="handleEdit(action.row)"
                          type="warning"
                          size="sm"
                          icon
                          >
                          UBAH
                          </base-button>
                      </div>
                    </el-table-column>
                  </el-table>

                  <div class="py-3">

                      <el-select
                        class="select-primary pagination-select mr-3"
                        v-model="pagination.perPage"
                        placeholder="Per page"
                        @change="changeSizePage()"
                        >
                        <el-option
                            class="select-primary"
                            v-for="item in pagination.perPageOptions"
                            :key="item"
                            :label="item"
                            :value="item"
                        >
                        </el-option>
                      </el-select>

                      <small class="card-category">
                      Showing {{  1 }} to {{ listData.length }} of {{ pagination.total }} entries
                      </small>
                  </div>

                  <div style="display: flex;justify-content: flex-end;">
                    <base-pagination
                        class="pagination-no-border"
                        :current="pagination.currentPage"
                        :per-page="pagination.perPage"
                        :total="pagination.total"
                        @change="paginationChanged($event)"
                    >
                    </base-pagination>
                  </div>
                </section>
            </b-card>
          </b-col>
        </b-row>
      </b-container>

        <!-- Loading Screen -->
        <Modal
        :show="isLoading"
        modalContentClasses="popup-modal-backgrounds"
        modalClasses="modal-dialog-scrollable"
        size="lg"
        style="background-color: rgba(0, 0, 0, 0.8)"
      >
        <div>
          <div id="announcement" class="rounded overflow-hidden mdl-contentpopups">
            <div class="p-4">
              <div class="row">
                <div class="col-lg-12 text-center" style="position: relative;">
                  <img src="img/brand/img-logo-color.png" alt="">
                  <img src="img/brand/klhk.png" alt="" style="position: absolute;right: 0;top: -200px;opacity: 0.2;">
                  <h1>Menyiapkan Data...</h1>
                  <h5>Harap tunggu beberapa saat</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>

      <!-- Download Modal PDF -->
      <Modal
        :show="showDownloadModal"
        modalContentClasses="popup-modal-backgrounds"
        modalClasses="modal-dialog-scrollable"
        @close="showDownloadModal = false"
        size="xl"
      >
        <div>
          <div id="announcement" class="rounded overflow-hidden mdl-contentpopups">
            <div class="p-4" style="height: 500px;overflow: scroll;">
              <div id="printBos">
                <div>
                  <div style="display: flex;justify-content: space-between;">
                    <div>
                      <div style="display: flex;justify-content: center;align-items: center;">
                        <div style="width: 50px;height: 50px;">
                          <img src="/img/brand/img-logo-color.png" alt="" style="width: 100%;">
                        </div>
                        <h3 class="ml-3">Kementerian Lingkungan Hidup</h3>
                      </div>
                    </div>
                    <div>
                      <h2>{{ pdfName }}</h2>
                      <small><b>Pemanfaatan Air Limbah</b></small><br>
                      <small>Data ini dibuat pada 12-20-2024</small>
                    </div>
                  </div>
                  <table class="table mt-3">
                    <thead class="bg-primary">
                      <tr>
                        <th class="text-white">No</th>
                        <th class="text-white">Debit air recovery</th>
                        <th class="text-white">Debit air recycle</th>
                        <th class="text-white">Debit air reuse</th>
                        <th class="text-white">Debit air Limbah diolah IPAL</th>
                        <th class="text-white">Debit air Limbah total</th>
                        <th class="text-white">Debit penggunaan air</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(item, idx) in listDataDownload">
                        <td>{{ idx + 1 }}</td>
                        <td><span>{{item.recovery_water_value }} {{item.recovery_water_unit }} (<i>{{item.recovery_water_desc }}</i> )</span></td>
                        <td><span>{{item.recycle_water_value }} {{item.recycle_water_unit }} (<i>{{item.recycle_water_desc }}</i> )</span></td>
                        <td><span>{{item.reuse_water_value }} {{item.reuse_water_unit }} (<i>{{item.reuse_water_desc }}</i> )</span></td>
                        <td><span>{{item.waste_water_ipal_value }} {{item.waste_water_ipal_unit }} (<i>{{item.waste_water_ipal_desc }}</i> )</span></td>
                        <td><span>{{item.waste_water_total_value }} {{item.waste_water_total_unit }} (<i>{{item.waste_water_total_desc }}</i> )</span></td>
                        <td><span>{{item.water_usage_value }} {{item.water_usage_unit }} (<i>{{item.water_usage_desc }}</i> )</span></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>


              <!-- <div class="mt-5">
                <button class="btn btn-success" @click="exportExcel">Unduh Excel</button>
              </div> -->

              <!-- <ReportExcel/> -->


            </div>
          </div>
        </div>
        <div class="mt-4">
          <div class="row">
            <div class="col text-right">
              <button class="btn btn-primary" @click="print">Unduh PDF</button>
              <base-button
                class="bg-danger border-0"
                @click="showDownloadModal = false"
              >
                Tutup
              </base-button>
            </div>
          </div>
        </div>
      </Modal>

      <!-- Download Modal Excel -->
      <Modal
        :show="showDownloadModalCSV"
        modalContentClasses="popup-modal-backgrounds"
        modalClasses="modal-dialog-scrollable"
        @close="showDownloadModalCSV = false"
        size="xl"
      >
        <div>
          <div id="announcement" class="rounded overflow-hidden mdl-contentpopups">
            <div class="p-4" style="height: 500px;overflow: scroll;">
              <div>
                <div>

                  <div style="padding:10px;overflow-x: scroll;" >
                    <!-- <table border="1" class="content-table"  ref="exportable_table">
                        <thead class="">
                            <tr>
                              <th>No</th>
                              <th>Titik Penaatan</th>
                              <th>Kondisi</th>
                              <th>Lokasi</th>
                              <th>Lokasi</th>
                              <th>Lokasi</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                              <td>1</td>
                              <td>1</td>
                              <td>1</td>
                              <td>1</td>
                              <td>1</td>
                              <td>1</td>
                            </tr>
                        </tbody>
                    </table> -->
                    <table class="table mt-3" ref="exportable_table">
                      <thead class="bg-primary">
                      <tr>
                        <th class="text-white">No</th>
                        <th class="text-white">Debit air recovery</th>
                        <th class="text-white">Debit air recycle</th>
                        <th class="text-white">Debit air reuse</th>
                        <th class="text-white">Debit air Limbah diolah IPAL</th>
                        <th class="text-white">Debit air Limbah total</th>
                        <th class="text-white">Debit penggunaan air</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(item, idx) in listDataDownload">
                        <td>{{ idx + 1 }}</td>
                        <td><span>{{item.recovery_water_value }} {{item.recovery_water_unit }} (<i>{{item.recovery_water_desc }}</i> )</span></td>
                        <td><span>{{item.recycle_water_value }} {{item.recycle_water_unit }} (<i>{{item.recycle_water_desc }}</i> )</span></td>
                        <td><span>{{item.reuse_water_value }} {{item.reuse_water_unit }} (<i>{{item.reuse_water_desc }}</i> )</span></td>
                        <td><span>{{item.waste_water_ipal_value }} {{item.waste_water_ipal_unit }} (<i>{{item.waste_water_ipal_desc }}</i> )</span></td>
                        <td><span>{{item.waste_water_total_value }} {{item.waste_water_total_unit }} (<i>{{item.waste_water_total_desc }}</i> )</span></td>
                        <td><span>{{item.water_usage_value }} {{item.water_usage_unit }} (<i>{{item.water_usage_desc }}</i> )</span></td>
                      </tr>
                    </tbody>
                  </table>
                  </div>


                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="mt-4">
          <div class="row">
            <div class="col text-right">
              <base-button
                class="bg-primary border-0"
                @click="ExportExcel('xlsx')"
              >
                Unduh Excel
              </base-button>
              <base-button
                class="bg-danger border-0"
                @click="showDownloadModalCSV = false"
              >
                Tutup
              </base-button>
            </div>
          </div>
        </div>
      </Modal>
  
    </div>
  </template>
  <script>
  import API from "@/api/base_url.js";
  import { Select, Option, Table, TableColumn } from "element-ui";
  import PendaftaranSparingTable from "../../../Tables/PendaftaranByCompany/PengolahanKembaliTable.vue"
  import BaseHeader from "@/components/BaseHeader";
  import moment from 'moment'
  import flatPicker from "vue-flatpickr-component";
  import "flatpickr/dist/flatpickr.css";

  // new
  import Modal from "@/components/Modal.vue";
  import Swal from "sweetalert2";
  import "sweetalert2/dist/sweetalert2.css";
  import ShortcutButtonsPlugin from "shortcut-buttons-flatpickr";
  import html2pdf from "html2pdf.js";
  
  export default {
    name: "pendaftaranSparing",
    components: {
      BaseHeader,
      Modal,
      flatPicker,
      [Select.name]: Select,
      [Option.name]: Option,
      [Table.name]: Table,
      [TableColumn.name]: TableColumn,
      PendaftaranSparingTable
    },
    mounted() {
      this.getData()
        // new
      this.log_as = localStorage.getItem("as");
    },
    data() {
      return {
        log_as: "",
        pdfName:"",
        listDataDownload: [],
        listDataCompliencePoints: [],
        filterStatusPendaftaran: "",
        selectOptionStatus: [
        {
          val:"",
          name:"Semua"
        },
        {
          val: "Menunggu Validasi",
          name:"Menunggu"
        },
        {
          val: "Diterima",
          name:"Disetujui"
        },
        {
          val: "Ditolak",
          name:"Ditolak"
        },
        ],
        userInput: {
        tanggal: "",
        registrationType:"SP",
        titikPenaatan:"",
        companyType:"",
        companyName:"",
        provinsi:[],
        kabupatenKota:[],
        regency:[]
        },
        provideSelect: {
        flatPickerConfig: {
          allowInput: true,
          altInput: true,
          altFormat: "d-m-Y H:i",
          enableTime: false,
          time_24hr: true,
          plugins: [
            ShortcutButtonsPlugin({
              button: [
                {
                  label: "Data Hari ini",
                  attributes:{
                    class:"btn w-75 btn-dark mb-2 btn-sm"
                  }
                },
                {
                  label: "Data 1 Minggu",
                  attributes:{
                    class:"btn w-75 btn-dark mb-2 btn-sm"
                  }
                },
                {
                  label: "Data 1 Bulan",
                  attributes:{
                    class:"btn w-75 btn-dark mb-5 btn-sm"
                  }
                },
                {
                  label: "Reset Pencarian Tanggal",
                  attributes:{
                    class:"btn w-100 btn-danger btn-sm"
                  }
                },
              ],
              onClick:(index,fp)=>{
                fp.clear();

                if(index == 0){
                const startDate = moment().startOf('day');
                  const endDate = moment(startDate).endOf('day');
                  fp.setDate([startDate.toDate(), endDate.toDate()]);
                  
                }else if(index == 1){
                  const startDate = moment().startOf('day');
                  const endDate = moment(startDate).add(1, 'week').endOf('day');
                  fp.setDate([startDate.toDate(), endDate.toDate()]);

                 
                }else if(index == 2){
                  const startDate = moment().startOf('month');
                  const endDate = moment().endOf('month');
                  fp.setDate([startDate.toDate(), endDate.toDate()]);
                }else{
                  fp.clear();
                }
              }
            }),
          ], // Gunakan plugin di sini

          defaultHour: 0,
          mode: "range",
        },
        registrationType:[],
        compiencePoint:[],
        companyType:[],
        companyName:[],
        provinsi:[],
        kabupatenKota:[],
        regency:[]
        },
        form: {
          umum: {
            compName: null,
            compNameID: null,
            compType: null,
            compCity: "",
            compProvince: "",
          },
        },
        isLoading: false,
        showDownloadModal:false,
        showDownloadModalCSV:false,
      // end of filter
        pagination: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [5, 10, 20, 50],
        total: 1
        },
        listData : [],
      };
    },
    methods: {
      getData() {
        this.isLoading = true
        let headers = {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }

        API.get(`waste-recycles?page=${this.pagination.currentPage}&page_size=${this.pagination.perPage}` , {headers})
          .then(({data}) => {
            if (data.data == null) {
              this.listData = []
            } else {
              this.listData = data.data
              this.pagination.total = data.meta.pagination.total_items
            }
              this.isLoading = false
          })
          .catch((err) => {
            Swal.fire('Gagal mendapatkan Data','Sistem gagal menampilkan data, terjadi kesalahan','error')
            this.isLoading = false
        })
  
      },
      getDataDownload() {
        this.isLoading = true

        let tmpDate = this.userInput.tanggal.split("to")

        let startDate = tmpDate[0]
        let endDate = tmpDate[1]


        if (endDate == undefined) {
          endDate = startDate
        } else if (endDate) {
          endDate.trim()
          startDate.trim()
        }


        let headers = {
        Authorization: `Bearer ${localStorage.getItem('token')}`
        }

        const queryParams = {
          status:this.filterStatusPendaftaran,
          start_date: startDate,
          end_date:endDate,
          province_id:this.form.umum.compProvince,
          regency_id:this.form.umum.compCity,
          company_id:this.userInput.companyName,
          company_type_id:this.userInput.companyType,
          sparing_compliance_point_id:this.userInput.titikPenaatan
        };

        const params = Object.fromEntries(
            Object.entries(queryParams).filter(([key, value]) => value !== undefined && value !== null && value !== "")
          );


          API.get(`companies/waste-uses` , { params, headers})
            .then(({data}) => {
              if (data.data == null) {
                  this.listDataDownload = []
                  this.isLoading = false
                } else {
                  this.listDataDownload = data.data
                  this.pagination.total = data.meta.info.count
                  setTimeout(() => {
                    this.isLoading = false
                  }, 200);
                }
            })
            .catch((err) => {
              this.isLoading = false
              console.log(err);
            })
      },
      downloadPDFNow() {
        this.getDataDownload()
        this.showDownloadModal = true      
      },
      downloadExcelNow() {
        this.getDataDownload()
        this.showDownloadModalCSV = true
      },
      getCompanyProfile() {
        let headers = {
            Authorization: `Bearer ${localStorage.getItem('token')}`
          }

          API.get(`companies/me` , {headers})
            .then(({data}) => {
              if (data.data == null) {

              } else {
                this.companyMe = data.data

                if (this.log_as !== 'admin') {
                  this.userInput.companyName = data.data.company.id
                  this.pdfName = data.data.company.name
                }
              }
            
            })
            .catch((err) => {
              console.log(err);
          })
      },
      getCompanyType() {
      let headers = {
            Authorization: `Bearer ${localStorage.getItem('token')}`
      }

      API.get('companies/types?page=1&page_size=100', {headers})
        .then(({data:content}) => {
          this.provideSelect.companyType = content.data
        })
        .catch((err) => {
          console.log(err);
        })
      },
      getCompanyName() {
      let headers = {
            Authorization: `Bearer ${localStorage.getItem('token')}`
      }

      API.get('companies?page=1&page_size=1000', {headers})
        .then(({data:content}) => {
          this.provideSelect.companyName = content.data
        })
        .catch((err) => {
          console.log(err);
        })
      },
      getSelectFormValue() {
      API.get(`region/provinces?page=1&page_size=100`)
        .then(({ data: content }) => {
          this.provideSelect.provinsi = content.data;
        })
        .catch((err) => {
          console.log(err);
        });
      },
      getCityDepentOnProvince(data) {
        this.provideSelect.kabupatenKota = [];
        API.get(`region/provinces/regencies?province_id=${data}`)
        // API.get(`region/regencies/province/${data}`)
          .then(({ data: content }) => {
            this.provideSelect.kabupatenKota = content.data;
          })
          .catch((err) => {
            console.log(err);
          });
      },
      getCompliencePoint() {
        let headers = {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }

        API.get(`companies/waste-uses/compliance-points` , {headers})
        .then(({data:content}) => {
          this.listDataCompliencePoints = content.data
        })
        .catch((err) => {
          console.log(err);
        })
      },
      getMyCompliencePoint() {
        let headers = {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }

        API.get(`companies/waste-uses/compliance-points?company_id=${this.userInput.companyName}` , {headers})
        .then(({data:content}) => {
          this.listDataCompliencePoints = content.data
        })
        .catch((err) => {
          console.log(err);
        })
      },
      getBaseOnStatus() {
      
      this.isLoading = true

      let tmpDate = this.userInput.tanggal.split("to")

      let startDate = tmpDate[0]
      let endDate = tmpDate[1]


      if (endDate == undefined) {
        endDate = startDate
      } else if (endDate) {
        endDate.trim()
        startDate.trim()
      }


      let headers = {
      Authorization: `Bearer ${localStorage.getItem('token')}`
      }

      const queryParams = {
        page:this.pagination.currentPage,
        page_size:this.pagination.perPage,
        status:this.filterStatusPendaftaran,
        start_date: startDate,
        end_date:endDate,
        province_id:this.form.umum.compProvince,
        regency_id:this.form.umum.compCity,
        company_id:this.userInput.companyName,
        company_type_id:this.userInput.companyType,
        sparing_compliance_point_id:this.userInput.titikPenaatan
      };

      const params = Object.fromEntries(
          Object.entries(queryParams).filter(([key, value]) => value !== undefined && value !== null && value !== "")
        );


      API.get(`waste-recycles` , { params, headers})
        .then(({data}) => {
          if (data.data == null) {
              this.listData = []
              this.isLoading = false
            } else {
              this.listData = data.data
              this.pagination.total = data.meta.info.count
              setTimeout(() => {
                this.isLoading = false
              }, 200);
            }
        })
        .catch((err) => {
          this.isLoading = false
          console.log(err);
        })
      },
      print() {
        var element = document.getElementById("printBos");
        var opt = {
          margin: 0.5,
          filename: "myfile.pdf",
          image: { type: "jpeg", quality: 0.98 },
          html2canvas: { scale: 2 },
          jsPDF: { unit: "in", format: "letter", orientation: "landscape" },
        }
        // New Promise-based usage:
        html2pdf().set(opt).from(element).save();
        Swal.fire('Data di Unduh','','success')
        this.showDownloadModal = false
      },
      ExportExcel(type, fn, dl) {
          var elt = this.$refs.exportable_table;
          var wb = XLSX.utils.table_to_book(elt, { sheet: "Sheet JS" });
          Swal.fire('Data di Unduh','','success')
          this.showDownloadModalCSV = false
          return dl ?
              XLSX.write(wb, { bookType: type, bookSST: true, type: 'base64' }) :
              XLSX.writeFile(wb, fn || ('SheetJSTableExport.' + (type || 'xlsx')));
      },
      reportTableState() {
          return []
      },
      // new
      formatDate(timestamp) {
        const momentObj = moment.unix(timestamp);
        const humanDate = momentObj.format('DD-MM-YYYY - HH:mm:ss');
        return humanDate;
      },
      getStyleObject(val) {
      let backgroundColor;

      switch (val) {
        case "Menunggu Validasi":
          backgroundColor = "rgb(245 158 11)";
          break;
        case "Diterima":
          backgroundColor = "rgb(20 83 45)";
          break;
        case "Ditolak":
          backgroundColor = "#dc3545";
          break;
        default:
          backgroundColor = "white";
      }

      return {
        backgroundColor,
        // Anda dapat menambahkan properti CSS lainnya di sini
      };

      },
      changeSizePage() {
        this.getData()
      },
      paginationChanged(page) {
        this.pagination.currentPage = page;
        this.getData()
      },
      
      handleEdit(row) {
        Swal
          .fire({
            title: "Ubah data ?",
            text: ``,
            type: "warning",
            showCancelButton: true,
            confirmButtonClass: "btn btn-primary btn-fill",
            cancelButtonClass: "btn btn-danger btn-fill",
            confirmButtonText: "UBAH",
            cancelButtonText: "BATAL",
            buttonsStyling: false,
          })
          .then((result) => {
            if (result.value) {
              // if row.type == SP || CT ETC
              // this.$router.push(`/edit-sparing/${row.id}`);
              this.$router.push(`/pengolahan-kembali/update/${row.id}`);
            }
          });
      },
      
      view(get) {
          this.$router.push(`/pengolahan-kembali/rincian/${get}`);
          
          console.log("view");
      },
      aliasStatus(status) {
        let result = ""

        switch (status) {
          case 0:
            result = 'Menunggu'
            break;
          case 1:
            result = 'Disetujui'
            break;
          case 2:
            result = 'Ditolak'
            break;
          case 3:
            result = 'Menunggu Perubahan'
            break;

          default:
            break;
        }

        return result
      },
      goToRegisSparingWasteWater() {
        this.$router.push("/pengolahan-kembali/air-limbah/daftar");
      },
    },
  };
  </script>
  