<template>
    <div class="content">
      <div>
      <div>
      <b-col cols="12" class="mb-3 d-flex  flex-wrap">
      </b-col>
      
  
      <el-table
          :data="queriedData"
          row-key="id"
          header-row-class-name="thead-light"
          @sort-change="sortChange"
      >

          <el-table-column label="No" type="index" width="100" />

          <el-table-column min-width="220px"  label="STATUS">
            <div slot-scope="action">
               {{ aliasStatus(action.row.status) }}
            </div>
          </el-table-column>

          <el-table-column min-width="220px"  label="dibuat">
            <div slot-scope="action">
               01-01-2020
            </div>
          </el-table-column>

          <el-table-column min-width="220px"  label="dirubah">
            <div slot-scope="action">
              01-01-2020
            </div>
          </el-table-column>


          <el-table-column min-width="250px"  label="AKSI">
            <div slot-scope="action">
                <base-button
                @click="view(action.row.id)"
                type="secondary"
                size="sm"
                icon
                >
                DETAIL
                </base-button>
                <!-- edit -->
                <base-button
                @click="handleEdit(action.row)"
                type="warning"
                size="sm"
                icon
                >
                EDIT
                </base-button>
            </div>
          </el-table-column>
      </el-table>
      </div>
      <div
      slot="footer"
      class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap mt-3"
      >
      <el-select
          class="select-primary pagination-select mr-3"
          v-model="pagination.perPage"
          placeholder="Per page"
          >
          <el-option
              class="select-primary"
              v-for="item in pagination.perPageOptions"
              :key="item"
              :label="item"
              :value="item"
          >
          </el-option>
          </el-select>
      <div class="">
        
          <p class="card-category">
          Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
  
          <span v-if="selectedRows.length">
              &nbsp; &nbsp; {{ selectedRows.length }} rows selected
          </span>
          </p>
      </div>
      <base-pagination
          class="pagination-no-border"
          :current="pagination.currentPage"
          :per-page="pagination.perPage"
          :total="total"
          @change="paginationChanged($event)"
      >
      </base-pagination>
      </div>
      </div>
    </div>
  </template>
  <script>
  import { Table, TableColumn, Select, Option } from "element-ui";
  import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
  import { BasePagination } from "@/components";
  import clientPaginationMixin from "../PaginatedTables/clientPaginationMixin";
  import swal from "sweetalert2";
  import users from "../dummyPendaftaranSparing";
  import { format } from 'date-fns';
  
  export default {
    props: {
      tableData: {
        type: Array,
        required: true
      }
    },
    mixins: [clientPaginationMixin],
    components: {
      BasePagination,
      RouteBreadCrumb,
      [Select.name]: Select,
      [Option.name]: Option,
      [Table.name]: Table,
      [TableColumn.name]: TableColumn,
    },
    data() {
      return {
        tableColumns: [
          {
            prop: "id",
            label: "Nomor",
            minWidth: 150,
          },
        ],
        selectedRows: [],
        filterStatusPendaftaran : '',
        filterJenisPendaftaran : "",
        // selectOptionRegister: ['Semua','Sparing','Uji Konektifitas', 'Pemanfaatan Air Limbah', 'Pelaporan Progress']
        selectOptionRegister: [
          {
            val:"",
            name:"Semua"
          },
          {
            val:"SP",
            name:"Sparing"
          },
          {
            val:"PG",
            name:"Pelaporan Progres"
          },
          {
            val:"RP",
            name:"Pemanfaatan Kembali"
          },
          {
            val:"CT",
            name:"Uji Konek"
          },
        ]
      };
    },
    methods: {
      getButtonAction(status) {
        switch (status) {
          case 'VA':
            return 'Perubahan Data'
            break;

          case 'WV':
            return 'Edit Data'
            break;

          case 'VR':
            return 'Pengajuan ulang'
            break;

          case 'VR':
            return 'Pengajuan ulang'
            break;

          case 'WC':
            return 'Edit Data'
            break;
        
          default:
            break;
        }
      },
      ConvertDateFunction(theDate) {
        let result = format(new Date(theDate), 'dd-MM-yyyy HH:mm')

        return result
      },
      aliasStatus(status) {

        let result = ""

        switch (status) {
          case 0:
            result = 'Waiting'
            break;
          case 1:
            result = 'Approved'
            break;
          case 2:
            result = 'Rejected'
            break;
          case 3:
            result = 'Waiting Change'
            break;
        
          default:
            break;
        }

        return result
      },
      aliasPendaftaran(type) {

        let result = ""

        switch (type) {
          case 'SP':
            result = 'Sparing'
            break;
          case 'PG':
            result = 'Pelaporan Progres'
            break;
          case 'RP':
            result = 'Pemanfaatan Kembali'
            break;
          case 'CT':
            result = 'Uji Konek'
            break;
        
          default:
            break;
        }

        return result
      },
      paginationChanged(page) {
        this.pagination.currentPage = page;
      },
      handleLike(index, row) {
        swal.fire({
          title: `You liked ${row.name}`,
          buttonsStyling: false,
          type: "success",
          confirmButtonClass: "btn btn-success btn-fill",
        });
      },
      handleEdit(row) {
        swal
          .fire({
            title: "Ubah data ?",
            text: `Anda yakin akan mengubah data dengan nomor pendaftaran ${row.id}`,
            type: "warning",
            showCancelButton: true,
            confirmButtonClass: "btn btn-primary btn-fill",
            cancelButtonClass: "btn btn-danger btn-fill",
            confirmButtonText: "Yes",
            buttonsStyling: false,
          })
          .then((result) => {
            if (result.value) {
              // if row.type == SP || CT ETC
              // this.$router.push(`/edit-sparing/${row.id}`);
              this.$router.push(`/pengolahan-kembali/update/${row.id}`);
            }
          });
      },
      handleDelete(row) {
        swal
          .fire({
            title: "Anda yakin akan menghapus data?",
            type: "warning",
            showCancelButton: true,
            confirmButtonClass: "btn btn-primary btn-fill",
            cancelButtonClass: "btn btn-danger btn-fill",
            confirmButtonText: "Yes, delete it!",
            buttonsStyling: false,
          })
          .then((result) => {
            if (result.value) {
              swal.fire({
                title: "Deleted!",
                text: `You deleted ${row.uid}`,
                type: "success",
                confirmButtonClass: "btn btn-success btn-fill",
                buttonsStyling: false,
              });
            }
          });
      },
      view(get) {
          this.$router.push(`/pengolahan-kembali/rincian/${get}`);
          
          console.log("view");
      }
    },
  };
  </script>
  <style>
  .no-border-card .card-footer {
    border-top: 0;
  }

  
  </style>
  